<template>
	<v-sheet v-if="group_id != null">
		<v-toolbar flat>
			<v-toolbar-title v-text="displayTimesheetDate">
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn text color="primary" small @click="$router.go(-1)">返回上页</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<v-container v-if="project">
			<div class="d-flex px-1 py-2 align-center">
				<div class="subtitle-2 mr-2" style="width:140px">项目名称:</div>
				<v-chip label :to="{ name: 'viewProject', params: {id:project.id}}">{{project.name}} <v-icon small right>mdi-open-in-new</v-icon></v-chip> 
			</div>
			<div class="d-flex px-1 py-2 align-center">
				<div class="subtitle-2 mr-2" style="width:140px">建筑商:</div>
				<div class="body-2 pl-3">{{project.builder_name}}</div>
			</div>
			<div class="d-flex px-1 py-2 align-center">
				<div class="subtitle-2 mr-2" style="width:140px">工作记录状态:</div>
				<div class="body-2">
					<template v-if="timesheet_status === 0">
						<v-chip
							color="warn"
							>
							待递交
						</v-chip>
					</template>
					<template v-else-if="timesheet_status === 1">
						<v-chip
							color="info"
							>
							待审核
						</v-chip>
					</template>
					<template v-else>
						<v-chip
							color="success"
							>
							已审核
						</v-chip>
					</template>
				</div>
			</div>
		</v-container>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-container class="pa-0" style="margin-bottom:140px;">
				<v-divider></v-divider>
				<div class="py-3 d-flex flex-column">
					<div class="subtitle-1  px-3">当日工作团队数据</div>
					<div class="px-3 mt-4 mb-3 d-flex" v-if="teams && teams.length">
						<div class="body-2 ">
							<div><span class="caption mr-3">当日天工总人数:</span> <strong>  {{getDayLabourNumberTotal}}</strong> </div>
							<div><span class="caption mr-3">当日包工总人数:</span> <strong>  {{getDayPackageNumberTotal}}</strong></div>
							<v-divider class="my-2"></v-divider>
							<div><span class="caption mr-3">当日工作总人数:</span> <strong>  {{ parseInt(getDayLabourNumberTotal) + parseInt(getDayPackageNumberTotal) }}</strong></div>
						</div>
						<v-divider class="mx-4" vertical></v-divider>
						<div class="body-2 ">
							<div><span class="caption mr-3">当日天工总小时:</span> <strong>  {{getDayLabourHourTotal}}</strong> </div>
							<div><span class="caption mr-3">当日包工总小时:</span> <strong>  {{getDayPackageHoursrTotal}}</strong></div>
							<v-divider class="my-2"></v-divider>
							<div><span class="caption mr-3">当日工作总小时:</span> <strong>  {{ parseFloat(getDayLabourHourTotal) + parseFloat(getDayPackageHoursrTotal) }}</strong></div>
						</div>
					</div>
				</div>
				<template v-if="teams.length">
					<v-divider></v-divider>
					<v-divider class="mt-1"></v-divider>
					<v-list flat three-line class="pa-0">
						<v-list-item-group v-for="team in teams" :key="team.id">
							<v-list-item class="py-4">
								<v-list-item-content>
									<v-list-item-title><strong>{{team.work_team.name}}</strong></v-list-item-title>
									<v-list-item-subtitle class="mt-2"><strong class="mr-2">类别:</strong>{{team.work_team.type | getTypeLabel}}</v-list-item-subtitle>
									<div class="mt-4 d-flex">
										<div class="caption mr-2">
											<div>
												<strong class="mr-2">天工人数:</strong>{{team.day_labour.count}}
											</div>
											<div>
												<strong class="mr-2">包工人数:</strong>{{team.package.count}}
											</div>
											<v-divider class="my-2"></v-divider>
											<div>
												<strong class="mr-2">工人总数:</strong>{{ parseInt(team.day_labour.count) + parseInt(team.package.count) }}
											</div>
										</div>
										<v-divider class="mx-2" vertical></v-divider>
										<div class="caption ml-2">
											<div>
												<strong class="mr-2">天工时间:</strong>{{team.day_labour.total_hours}} 小时
											</div>
											<div>
												<strong class="mr-2">包工时间:</strong>{{team.package.total_hours}} 小时
											</div>
											<v-divider class="my-2"></v-divider>
											<div>
												<strong class="mr-2">工人总时:</strong>{{ parseFloat(team.day_labour.total_hours) + parseFloat(team.package.total_hours) }} 小时
											</div>
										</div>
									</div>
								</v-list-item-content>
										<v-divider vertical></v-divider>
								<v-list-item-action>
									<v-btn
										text
										color="primary"
										class="mb-2"
										:to="{ name: 'timesheetTeam', params: {id:team.id}, query: { status: canUserEditTimesheet, pid:project.id  }}"
										>
										<template v-if="canUserEditTimesheet">
											<v-icon small left>mdi-pencil</v-icon>编辑
										</template>
										<template v-else>
											<v-icon small left>mdi-eye</v-icon>查看
										</template>
									</v-btn>
									<v-btn
										v-if="canUserEditTimesheet"
										text
										color="error"
										@click.prevent="deleteTeamFromTimesheet(team.id)"
										>
										<v-icon small left>
											mdi-delete
										</v-icon>
										移除
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider></v-divider>
						</v-list-item-group>
					</v-list>
				</template>
				<template v-else>
					<v-alert
						class="ma-3"
						text
						dense
						type="info"
						>
							<div class="body-2">您还没有添加当天参与工作的团队。</div>
					</v-alert>
				</template>
			</v-container>
		</template>
	
		<!--- Add Team Fab Button ---->
		<v-container class="fixedSaveButton" v-if="canUserEditTimesheet">
			<v-divider class="mb-3"></v-divider>
			<div class="d-flex justify-space-between">
				<div v-if="user && user.role === 'admin' && timesheet_status === 1">
					<v-btn
						elevation="0"
						color="primary"
						@click="updateTimesheet(2)"
						>
						<v-icon small left>mdi-briefcase-upload-outline</v-icon>批准工作记录
					</v-btn>
				</div>
				<div v-else-if="timesheet_status === 0">
					<v-btn
						elevation="0"
						color="primary"
						@click="updateTimesheet(1)"
						>
						<v-icon small left>mdi-briefcase-upload-outline</v-icon>递交工作记录
					</v-btn>
				</div>
				<v-btn
					color="success"
					elevation="0"
					@click="dlgAddTeam = true"
					>
					<v-icon small left>mdi-account-multiple-plus</v-icon>添加工作团队
				</v-btn>
			</div>
		</v-container>
		<v-dialog v-model="dlgSubmitTimesheet" max-width="460">
			<v-card>
				<v-card-title>更新工作记录状态</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="pt-4">
					{{updateStatusMessage}}
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn color="error" text @click="dlgSubmitTimesheet = false">
						取消
					</v-btn>
					<v-btn color="success" elevation="0" @click="updateTimesheetGroupStatus">
						更新
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dlgAddTeam" max-width="460">
			<team-list
				:existingTeamNames="existingTeams"
				@on-team-select="assignTeamToTimesheet($event)">
				<template v-slot:title>
					选择需要添加的团队
				</template>
				<template v-slot:actions>
						<v-btn
						text
						color="error"
						elevation="0"
						@click="dlgAddTeam = false"
					>
						取消
					</v-btn>
				</template>
			</team-list>
		</v-dialog>
		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}
			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</v-sheet>
</template>

<script>
import TimesheetService from '../../services/Timesheet';
import { format, parse } from 'date-fns';
import { mapState } from 'vuex';
import TeamList from '../../components/Timesheet/teamList';

export default {
	name: 'viewTimesheetProject',
	components: {
		TeamList
	},
	created(){
		this.group_id = this.$route.params.id;
		this.getTimesheetGroup();
	},
	data: () => ({
		group_id: null,
		project: null,
		timesheet_date: null,
		teams: [],
		existingTeams: [],
		dlgAddTeam: false,
		dlgSubmitTimesheet: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		timesheet_status: null,
		loading: false,
		updateStatusMessage: ''
	}),
	computed: {
		displayTimesheetDate(){
			return `${this.timesheet_date}项目工作记录`;
		},
		...mapState('user', ['status',  'user'],),
		canUserEditTimesheet(){
			if( this.user && this.user.role === 'admin' ){
				return true;
			} else {
				return this.timesheet_status === 0 ? true : false;

			}
		},
		getDayLabourNumberTotal(){
			return this.teams.map(team=>{
					return team.day_labour.count;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getDayLabourHourTotal(){
			return this.teams.map(team=>{
					return team.day_labour.total_hours;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getDayPackageNumberTotal(){
			return this.teams.map(team=>{
					return team.package.count;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getDayPackageHoursrTotal(){
			return this.teams.map(team=>{
					return team.package.total_hours;
				}).reduce((result, item)=>{
					return result + item;
				})
		},
		getNumberOfTeams(){
			return this.teams.length;
		}
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	filters: {
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	methods: {
	async getTimesheetGroup(){
			//get selected date by this.datePircker date.
			try {
				let response = await TimesheetService.getTimeSheetGroup(this.group_id);
				if(response.data.statusCode === 200){
					this.project = response.data.data.project;
					this.timesheet_status = parseInt(response.data.data.status);
					this.teams = response.data.data.teams;
					this.existingTeams = this.teams.map(team=>{
						return team.name;
					})

					const date = parse(response.data.data.date, 'dd/MM/yyyy', new Date());
					this.timesheet_date = format(date, 'yyyy年MM月dd日');

				}
			} catch(error){
			}
		},
		async assignTeamToTimesheet(team){
			const body = {
				"timesheet_group_id": this.group_id,
				"team_id": team.id,
			}
			try {
				let response = await TimesheetService.createTimesheetWorkTeam(body);
				if(response.data.statusCode === 200){
					//this.dlgAddTeam = false;
					this.loading = true;
					this.snackbar.message = `团队${response.data.message}`;
					this.snackbar.color = 'success';
					this.getTimesheetGroup();
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
			}
		},
		async deleteTeamFromTimesheet(teamId){
			try {
				let response = await TimesheetService.deleteTimesheetWorkTeam(teamId);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.snackbar.message = `团队${response.data.message}`;
					this.snackbar.color = 'success';
					this.getTimesheetGroup();
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
			}
		},
		updateTimesheet(statusCode){
			if(statusCode === 1){
				this.dlgSubmitTimesheet = true;
				this.updateStatusMessage = `注意！请确认当日工作记录正确，工作记录递交之后您将不能再进行修改。`;
			} else if( statusCode === 2 ){
				this.dlgSubmitTimesheet = true;
				this.updateStatusMessage = `您确认当日的工作记录正确且通过审核吗？`;
			}
		},
		async updateTimesheetGroupStatus(){
			try {
				let newStatus = ( this.user && this.user.role === 'admin' && this.timesheet_status === 1 ) ?  2 : 1;
				let body = {
					"status": newStatus
				}
				let response = await TimesheetService.updateTimesheetGroup(this.group_id, body);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.snackbar.message = `工作记录${response.data.message}`;
					this.snackbar.color = 'success';
					this.getTimesheetGroup();
					this.dlgSubmitTimesheet = false;
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
			}
		}
	},
}
</script>
<style>
	.fixedSaveButton {
		position: fixed;
		bottom: 0px;
		left: 0;
		right: 0;
		background: #fff;
		padding-bottom: 32px;
	}
</style>
