import Api from '@/services/Api';

export default {
	listTimeSheetGroup(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/timesheet/group?${queryString}`);
	},
	createTimeSheetGroup(body){
		return Api().post(`/timesheet/group`, JSON.stringify(body));
	},
	getTimeSheetGroup(groupId){
		return Api().get(`/timesheet/group/${groupId}`);
	},
	getTimesheetRecordsByGroupId(groupId, workerId, workTeamId){
		return Api().get(`/timesheet/group/${groupId}/records?worker_id=${workerId}&work_team_id=${workTeamId}`);
	},
	updateTimesheetGroup(groupdId, body){
		return Api().put(`/timesheet/group/${groupdId}`, JSON.stringify(body));
	},
	createTimesheetWorkTeam(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().post(`/timesheet/workteam?${queryString}`);
	},
	getTimesheetWorkTeam(teamId){
		return Api().get(`timesheet/workteam/${teamId}`);
	},
	deleteTimesheetWorkTeam(teamId){
		return Api().delete(`timesheet/workteam/${teamId}`);
	},
	getWorkerTimesheet(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/timesheet?${queryString}`);
	},
	updateTimesheetWorkTeam(teamId, body){
		return Api().put(`/timesheet/workteam/${teamId}`, JSON.stringify(body));
	},
	getDayLabourTimesheets(teamId){
		return Api().get(`/timesheet/workteam/${teamId}/day_labour`);
	},
	getPackageLabourTimesheets(teamId){
		return Api().get(`/timesheet/workteam/${teamId}/package`);
	},
	updateDayLabourTimesheets(teamId, body){
		return Api().post(`/timesheet/workteam/${teamId}/day_labour`, JSON.stringify(body));
	},
	updatePackageLabourTimesheets(teamId, body){
		return Api().post(`/timesheet/workteam/${teamId}/package`, JSON.stringify(body));
	},
}